import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { InputWrapperNew, StyledModalNew, StyledBodyNew, StyledHeaderNew, ContainerNew, StyledInputNewP1, InputContainerNew, SubmitContainerNew,FieldTitleNew, NewEmployeeFieldsNew} from './styles';
import { UserButtonNew } from '../EngagementSection/styles';
import { isValidEmail } from '../../utils/methods';
import { withTranslation } from 'react-i18next';
class NewEmployeePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          fname: '',
          mail: ''
        }
      ],
      file: '',
      csvUpload: false,
      fileName: '',
    }
  }

  componentDidMount() {
    this.setState({
      file: '',
      data: [
        {
          fname: '',
          mail: ''
        }
      ],
      csvUpload: false,
      fileName: ''
    })
  }

  handleFiles = (e) => {
    this.setState({
      file: '',
      fileName: '',
      csvUpload: false,
    })
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    const array = document.getElementById('new-employee-file').value.split("\\");
    let checkCSV = array[array.length -1].split('.');
    if(checkCSV[checkCSV.length -1] == 'csv') {
      reader.onloadend = () => {
        this.setState({
          file: reader.result ? reader.result : 'data:',
          fileName: array[array.length - 1],
          csvUpload: true,
          data: [
            {
              fname: '',
              lname: '',
              mail: ''
            }
          ]
        });
      }
    }
    else {
      toast.error('Please select .csv format only');
      document.getElementById('new-employee-file').value = '';
    }
  }
  
  onChangeInput = (e, index) => {
    const duplicateData  = this.state.data;
    duplicateData[index][e.target.name] = e.target.value;
    this.setState({
      data: duplicateData,
      csvUpload: false,
      fileName: '',
      file: ''
    })    
  }

  deleteCSV = () => {
    document.getElementById('new-employee-file').value = '';
    this.setState({
      csvUpload: false,
      fileName: '',
      file: ''
    }, () => {
      this.setState({
        csvUpload: false,
        fileName: '',
        file: ''
      })
    })
  }

  showInputContainer = () => {
    const { data } = this.state;
    return data.map((details, index) => (
      <ContainerNew key={index}>
        <InputContainerNew>
          <NewEmployeeFieldsNew>
            <FieldTitleNew>{this.props.t("First Name")}<span style={{color: 'red'}}>*</span>:</FieldTitleNew>
            <StyledInputNewP1 marginTop={1} type="text" placeholder={this.props.t('First name')}  name='fname' value={details.fname}
              onChange={(e) => this.onChangeInput(e, index)}/>
            <FieldTitleNew>{this.props.t("Last Name")}:</FieldTitleNew>
            <StyledInputNewP1 marginTop={1} type="text" placeholder={this.props.t('Last name')}  name="lname"   value={details.lname}   onChange={(e) => this.onChangeInput(e, index)}/>
            <FieldTitleNew>{this.props.t("Email Address")}<span style={{color: 'red'}}>*</span> :</FieldTitleNew>
            <StyledInputNewP1 marginTop={1} type="text" placeholder={this.props.t('Email Address')} name='mail' value={details.mail} onChange={(e) => this.onChangeInput(e, index)}/>
           
          </NewEmployeeFieldsNew>
        </InputContainerNew>
      </ContainerNew>
    ))
  }

  addEmployeeInputContainer = () => {
    const duplicateData  = this.state.data;
    
    const obj = {
      fname: '',
      lname: '',
      mail: ''
    }
    duplicateData.push(obj);
    this.setState({
      data: duplicateData
    })
  }

  checkErrorMessage = () => {
    let result = {};
    result['errMessage'] = '';
    result['err'] = false;
    let i = 0;
    while(i<this.state.data.length){
      if(i>0 && this.state.data[i].fname.trim() == '' && this.state.data[i].mail.trim() == '') {
        i += 1;
      }
      else if(this.state.data[i].fname.trim() == '') {
        result['errMessage'] = this.props.t("First name can't be blank");
        result['err'] = true;
        i = this.state.data.length;
      }
      else if(this.state.data[i].mail.trim() == ''){
        result['errMessage'] = this.props.t("Email can't be blank");
        result['err'] = true;
        i = this.state.data.length;
      }
      else if(!isValidEmail(this.state.data[i].mail.trim())) {
        result['errMessage'] = this.props.t('Enter valid email');
        result['err'] = true;
        i = this.state.data.length;
      }
      else {
        i += 1;
      }
    }
    return result;
  }

  addEmployeeButton = () => {
    const { companyId, showModalPopUp, postAddEmployee, companyAuthDetails,  } = this.props;
    const { file, data } = this.state;

    window.console.log("companyAuthDetails",companyAuthDetails)

    if(companyAuthDetails && companyAuthDetails.length && companyAuthDetails[0].password) {
      let obj = {};
      obj['company_id'] = companyId;
      obj['company_password'] = companyAuthDetails[0].password;

      if(file && file.length > 0) {
        obj['csv_file'] = file;
        obj['file_status'] = 1;
        postAddEmployee(obj);
        this.setState({
          file: '',
          data: [
            {
              fname: '',
              lname: '',
              mail: ''
            }
          ],
          csvUpload: false,
          fileName: ''
        })
        showModalPopUp();
      }
      else {
        let result = this.checkErrorMessage();

        if(result['err']) {
          toast.error(result['errMessage']);
        }
        else {
          obj['file_status'] = 0;
          obj['employees'] = data;
          postAddEmployee(obj);
          this.setState({
            file: '',
            data: [
              {
                fname: '',
                lname: '',
                mail: ''
              }
            ],
            csvUpload: false,
            fileName: '',
          })
          showModalPopUp();
        }
      }      
    }
    else {
      toast.error('Company Password Required')
    }
  };

  render() {
    const { show } = this.props;
    const { fileName, csvUpload } = this.state;
    return(
      <StyledModalNew show={show} onHide={() => {this.props.showModalPopUp(); this.setState({data: [{
        fname: '',
        lname: '',
        mail: ''
      }], fileName: '', csvUpload: '', file: ''})}} >
        <StyledHeaderNew closeButton>
          <ContainerNew style={{flexWrap:"wrap", flexDirection:"row"}}>
            <div className="headerName">
              {this.props.t("Add New Employee")}
            </div>
            <div className="cross_img">
              <img src="/public/images/new_close_icon.png"  alt="" style={{"cursor": "pointer"}} onClick={() => {this.props.showModalPopUp(); this.setState({data: [{
                fname: '',
                lname: '',
                mail: ''
              }], fileName: '', csvUpload: '', file: ''})}}/>
            </div>
          </ContainerNew>
        </StyledHeaderNew>
        <StyledBodyNew>
          { this.showInputContainer() }
          <ContainerNew>
            <InputContainerNew Align={1}>
              <div className="background" onClick={this.addEmployeeInputContainer}>
                <div className="addMail">{this.props.t("Add More Emails")}</div>
              </div>
            </InputContainerNew>
            <InputContainerNew Align={1} style={{display:"block"}}>
              <div className="bulkEmail">
                <InputWrapperNew htmlFor="new-employee-file" bgColor={'none'} Color={'none'}>
                  {this.props.t("Switch to bulk email invite (CSV)")}
                  <input
                    id="new-employee-file"
                    type="file"
                    name="user"
                    multiple={false}
                    onChange={(e) => this.handleFiles(e)}
                    onClick={(e) => e.target.files[0] && this.handleFiles(e)}
                    accept=".csv"
                  />
                </InputWrapperNew>
              </div>
              { csvUpload && 
                <div className="SuccessUpload">
                  <i className="fa fa-trash" aria-hidden="true" onClick={this.deleteCSV} style={{paddingRight:"5px", cursor:"pointer"}}/>
                  {fileName}
                </div>
              }
            </InputContainerNew>
          </ContainerNew>
          <SubmitContainerNew>
            <UserButtonNew bgColor="#007AB1" width="550px" padding="0px" height="50px" Align={1} fontSize="12px"  onClick={this.addEmployeeButton}>
              <div className="send">
                {this.props.t("Send Invite")}
              </div>
            </UserButtonNew>
          </SubmitContainerNew>
        </StyledBodyNew>
      </StyledModalNew>
    );
  }
}

NewEmployeePopup.propTypes = {
  show: PropTypes.bool,
  showModalPopUp: PropTypes.func,
  postAddEmployee: PropTypes.func,
  companyId: PropTypes.number,
  companyAuthDetails: PropTypes.func,
  t: PropTypes.func,
  fetchInactiveUsersList: PropTypes.func,
  inactive : PropTypes.bool,
}

export default ((withTranslation())(NewEmployeePopup));