import React from 'react';
import PropTypes from 'prop-types';
import { StyledModal, StyledBody, StyledHeader, Container, StyledInputNewP1, InputContainer, SubmitContainer, FieldTitle, InputContainerr, StyledInputContainer, PopupHeaderNew } from './styles';
import { UserButtonUpdate } from '../EngagementSection/styles';
import { BoldV2, StyledModalV2 } from './styles';
import { StyledBodyV2, SubmitContainerV2 } from '../AddWellnessInitiative/styles';
import { UserButtonV2 } from '../EngagementSection/styles';
import { DeleteFiledIcon } from '../../utils/icons';

class AddDepartmentPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          department: '',
          id: ''
        }
      ],
      deleted: [],
      showDeleteDepartmentPopUp: false,
      currentId: null,
      currentDepartmentName: null
    }
  }

  componentDidMount() {
    const { data } = this.props;
  
    if (data && Array.isArray(data)) {
      const dept = data.filter((obj) => obj.id !== null);
      this.setState({
        data: dept
      });
    }
  }



  onChangeInput = (e, index) => {
    const duplicateData = this.state.data;
    duplicateData[index][e.target.name] = e.target.value;
    duplicateData[index]["change"] = true;
    this.setState({
      data: duplicateData,
    })
  }

  onToggleDeleteDepartmentPopup = (details) => {
    this.setState({ showDeleteDepartmentPopUp: !this.state.showDeleteDepartmentPopUp, currentId: details?.id, currentDepartmentName: details?.department });
  };

  showInputContainer = () => (
    (
      <Container >
        <FieldTitle style={{ right: '45px',color:'#005C87' }} paddingTop={1}>All Departments</FieldTitle>
        {this.state.data.map((details, index) => (
          <InputContainer key={index} marginTop={1} marginBottom={1}>
            <StyledInputContainer>
              <StyledInputNewP1 width='90%' type="text" placeholder='Enter Department' name='department' value={details.department}
                onChange={(e) => this.onChangeInput(e, index)} /> 
            </StyledInputContainer>
            <div style={{ cursor: 'pointer' }} onClick={() => this.onToggleDeleteDepartmentPopup(details, index)} className='delete'>{DeleteFiledIcon()}</div>
          </InputContainer>
        ))}
      </Container>)
  )

  handleDelete = () => {
    const newData = this.state.data.filter((obj) => obj.id != this.state.currentId)
    let deletearray = this.state.deleted;
    this.state.currentId && deletearray.push(this.state.currentId)
    this.setState({ data: newData, showDeleteDepartmentPopUp: !this.state.showDeleteDepartmentPopUp, deleted: deletearray })
  }

  deleteDepartmentPopup = () => (
    (<StyledModalV2
      show={this.state.showDeleteDepartmentPopUp ? true : false}
      onHide={this.onToggleDeleteDepartmentPopup}
      dialogClassName={'delete-popup'}
      backdropClassName='drop-back'
    >
      <StyledBodyV2>
        <BoldV2>
          Are you sure you want to <span className='name-in-popup'>Delete<br />
            this Department ({this.state.currentDepartmentName})?</span>
          <div className="subContent">
            Once you delete the department, then it<br />
            will be removed for all users in Woliba.<br />
            Also, users with this department would<br />
            no longer have a department so you’d<br />
            need to update those users.
          </div>
        </BoldV2>
        <SubmitContainerV2>
          <UserButtonV2
            radius="5px"
            bgColor="#FD7175"
            width="125px"
            height="50px"
            fontSize="18px"
            Align={1}
            float={1}
            marginLeft="0px"
            onClick={() => this.handleDelete()}
            font="Rubik"
            capitalize="capitalize"
            marginRight="15px"
          >
            {"Delete"}
          </UserButtonV2>
          <UserButtonV2
            radius="5px"
            bgColor="none"
            width="125px"
            height="50px"
            fontSize="18px"
            Align={1}
            float={1}
            marginLeft="0px"
            onClick={this.onToggleDeleteDepartmentPopup}
            outline={0}
            font="Rubik"
            capitalize="capitalize"
            textColor="#9C9C9C"
            border="border: 2px solid #9C9C9C"
            boxSizing="border-box"
          >
            cancel
          </UserButtonV2>
        </SubmitContainerV2>
      </StyledBodyV2>
    </StyledModalV2>
    )
  )

  addEmployeeInputContainer = () => {
    const duplicateData = this.state.data;
    const obj = {
      department: '',
    }
    duplicateData.push(obj);
    this.setState({
      data: duplicateData
    })
  }

  addDepartmentButton = () => {
    const info = {};
    const data = this.state.data.filter((obj) => obj.change === true);
    data.map((item) => {
      delete item.change
    })
    info['company_id'] = this.props.companyId
    info['department'] = data
    this.props.showModalPopUp()
    this.props.fetchEditDepartmentApi(info, { "delete_department": this.state.deleted, "delete_location": [] })
  };

  render() {
    const { show } = this.props;
    const { fileName, csvUpload } = this.state;
    return (
      <StyledModal show={show} onHide={this.props.showModalPopUp}>
        <StyledHeader closeButton>
          <PopupHeaderNew>
            <div className="headingTitle">
              Add / Edit Department
            </div>
            <div className="closeButton">
              <img src="/public/images/new_close_icon.png" alt="" style={{ "cursor": "pointer" }} onClick={this.props.showModalPopUp} />
            </div>
          </PopupHeaderNew>
        </StyledHeader>
        <StyledBody>
          {this.showInputContainer()}
          <Container maxWidth={"100%"}>
            <InputContainerr Align={1}>
              <div className="addMoreButton" onClick={this.addEmployeeInputContainer}>
                Add More
              </div>
            </InputContainerr>
            {
              csvUpload &&
              <InputContainer Align={1}>
                <div className="SuccessUpload">
                  <span className="glyphicon glyphicon-trash" onClick={this.deleteCSV} />
                  {fileName}
                </div>
              </InputContainer>
            }
            <hr className='divider'></hr>
          </Container>
          <SubmitContainer padding={1}>
            <UserButtonUpdate bgColor="#007AB1" width="550px" padding="0px" height="50px" Align={1} fontSize="12px" onClick={() => {this.addDepartmentButton()
              this.props.showModalPopUp}}>
              <div className="send">
                Save The Updates
              </div>
            </UserButtonUpdate>
          </SubmitContainer>
        </StyledBody>
        {this.deleteDepartmentPopup()}
      </StyledModal>
    );
  }
}

AddDepartmentPopup.propTypes = {
  show: PropTypes.bool,
  showModalPopUp: PropTypes.func,
  postAddEmployee: PropTypes.func,
  companyId: PropTypes.number,
  companyAuthDetails: PropTypes.func,
  fetchEditDepartmentApi: PropTypes.func,
  data: PropTypes.array
}

export default AddDepartmentPopup;